<template>
  <div>
    <!-- <div @click="$router.go(-1)"
      style="display: flex;align-items: center;margin-left: 20px;font-size: 20px;cursor: pointer;margin-top: 20px;">
      <a-icon style="font-size: 30px;margin-right: 10px;" type="left-circle" />
      <span>返回</span>
    </div> -->
    <a-card class="card" title="基本信息" :bordered="false">
      <a-form-model>
        <a-row class="form-row">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item  label="客户名称">
              <a-input v-model="details.customerName" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <a-form-model-item ref="sellerId" prop="sellerId"  label="卖方主体">
              <a-input v-model="details.sellerName" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <a-form-model-item ref="invoiceAmount" prop="invoiceAmount" label="合计开票金额">
              <div style="position: relative;">
                <a-input  v-model="details.invoiceAmount" disabled  />
                <span style="position: absolute;right: 10px;top: 50%;transform: translateY(-50%);">元</span>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="form-row">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="合计业务支出" ref="businessExpenses" prop="businessExpenses">
              <div style="position: relative;">
                <a-input v-model="details.businessExpenses" disabled/>
                <span style="position: absolute;right: 10px;top: 50%;transform: translateY(-50%);">元</span>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">  
            <a-form-model-item label="预期出库时间" >
              <a-date-picker style="width: 100%;" disabled :default-value="moment(formatDate(details.sellingTime), dateFormat)" :format="dateFormat" />
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">  
            <a-form-model-item label="预计回款时间" >
              <a-date-picker style="width: 100%;" v-if="details.expectReturnTime" disabled :default-value="moment(formatDate(details.expectReturnTime), dateFormat)" :format="dateFormat" />
              <span v-else>-</span>
            </a-form-model-item>
          </a-col>
          </a-row>
          <a-row>
            <a-col  :lg="6" :md="12" :sm="24">
            <div class="price-box">
              <div style="border-right: 1px solid #EBEAED;">
                <p>合计实际收款</p>
                <span>{{ details.amount }}元</span>
              </div>
             <div>
               <p>平均折扣:</p>
               <span v-if="details.averageDiscount">{{ details.averageDiscount }}</span> <span v-else>0</span> 折
             </div>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <card :detailsArr="details.sfaSaleDocketProductList" type="details" :isBackShow="false"></card>
    
  </div>
</template>
  
<script>
import moment from 'moment';
import { request } from '@/utils/request'
import card from './components/card.vue'
export default {
  name: 'addForm',
  components: { card },
  data() {
    return {
      details: null,
      dateFormat:"YYYY-MM-DD",
    }
  },
  mounted() {
    // 详情
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/'+this.$route.query.id, 'get').then(res => {
      this.details = res.data.data;
    })
  },
  methods: {
    moment,
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toISOString().split('T')[0];
    },
    back() {
      this.$router.replace('/sfa_workOrder/OutboundList')
    },

  }
}
</script>
  
<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}

::v-deep .ant-card-body {
  padding: 24px !important;
}

.price-box {
  display: flex;
  border-radius: 6px;
  margin-top: 6px;
  background: rgba(85, 66, 246, 0.03);
}

.price-box div {
  padding: 10px 0;
  box-sizing: border-box;
  flex: 1;
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #010101;
  text-align: center;
}

.price-box span {
  font-size: 18px;
  color: rgba(255, 0, 0, 1);
}


::v-deep .ant-input[disabled] {
  background: #fff;
  color: #333;
}
</style>
  